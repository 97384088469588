import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import backgroundImg from "../Logo/bg.png";

const Abt = () => {
  return (
    <div
      style={{
        paddingTop: "7%",
        paddingBottom: "7%",
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "orange",
      }}
    >
      <Container>
        <Row
          class="col"
          style={{
            textAlign: "justify",
            display: "flex ",
            justifyContent: "center",
          }}
        >
          <h1 className="head">OUR SERVICES</h1>
          <br />
          <br />
          <br />
          <Col md={4} xs={12}>
            <h4 className="type">1-Jerri's Couture & Scents </h4>
            Ultimate Shopping Experience with the Art of Selection Luxe Footwear
            & Embody your Olfactory Journeys with the Antique collection of
            Jerri's Couture.Happy Shopping..
          </Col>
          <Col md={4} xs={12}>
            <h4 className="type">2-Alia's E-Books Institute </h4>
            <p>
              Our vision is to shape a future where every young individual
              possesses the skills and knowledge to succeed in the digital
              world. We aim to serve as a catalyst for global innovation and
              socio-economic advancement.
            </p>
          </Col>
          <Col md={4} xs={12}>
            <h4 className="type">3-The saba-ly Therapeutic</h4>
            <p>
              The Saba-ly Therapeutic Institute health specialists work
              alongside primary care teams to provide personalized,
              comprehensive support, ensuring each individual receives the
              highest level of care.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Abt;
