import React from 'react';
import { Container } from 'react-bootstrap';

const Ebooks = () => {
  return (
    <div style={{paddingTop:"20px"}}>
      <Container style={{ backgroundColor: 'orange', textAlign: 'center', padding: '60px 20px', width: '90%', margin: 'auto' }}>
        <h1>
          OUR
          ALIA’S E-BOOKS INSTITUTE <br/>
        PROJECT WILL BE LUNCH SOON.....
        </h1>
      </Container>
    </div>
  );
}

export default Ebooks;

