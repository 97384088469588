import React from 'react';

const AnimatedBlocks = () => {
  return (
    <>
    <div className="containers-wrapper">
      {/* Repeat six containers of alternating colored blocks */}
      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>

      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>

      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>
      
      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>
      
      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>
      
      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>
      
      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>
      
      <div className="block-container">
        <div className="block orange"></div>
        <div className="block black"></div>
        <div className="block orange"></div>
      </div>

      <div className="block-container">
        <div className="block black"></div>
        <div className="block orange"></div>
        <div className="block black"></div>
      </div>
    </div>
    {/* <div className="containers-wrapper">
    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>

    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>

    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>

    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>

    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>
    
    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>

    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>
    
    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>

    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>
    
    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>

    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>
    
    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>

    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>
    
    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>

    <div className="block-container">
      <div className="block black"></div>
      <div className="block orange"></div>
      <div className="block black"></div>
    </div>
    <div className="block-container">
      <div className="block orange"></div>
      <div className="block black"></div>
      <div className="block orange"></div>
    </div>
  </div> */}
  </>
  );
};

export default AnimatedBlocks;



