import React, { useState } from "react";
import {Container, Col, Row } from 'react-bootstrap';
import img0 from  "../Logo/book4.png";
import img1 from  "../Logo/book3.png";
import img2 from  "../Logo/couture1.png";
import img3 from  "../Logo/couture.png";
import img4 from  "../Logo/therapy1.png";
import img5 from  "../Logo/therapy.png";

const Products = () => {
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  return (
    <Container>
      <Row>
        <Col md={4} xs={12}>
          <a href="/Project/book" className='a'>
            <div className="hover-image-container">
              <img
                src={isHovered1 ? img0 : img1}
                alt="Hover"
                className="img-fluid"
                onMouseOver={() => setIsHovered1(true)}
                onMouseOut={() => setIsHovered1(false)}
              />
            </div>
            <h4 className='naming'>Alia’s E-Books Institute</h4>
          </a>
        </Col>
        <Col md={4} xs={12}>
          <a href="/Project/jerri" className='a'>
            <div className="hover-image-container">
              <img
                src={isHovered2 ? img2 : img3}
                alt="Hover"
                className="img-fluid"
                onMouseOver={() => setIsHovered2(true)}
                onMouseOut={() => setIsHovered2(false)}
              />
            </div>
            <h4 className='naming'>Jerri’s Couture & Scents</h4>
          </a>
        </Col>
        <Col md={4} xs={12}>
          <a href="/Project/mind" className='a'>
            <div className="hover-image-container">
              <img
                src={isHovered3 ? img4 : img5}
                alt="Hover"
                className="img-fluid"
                onMouseOver={() => setIsHovered3(true)}
                onMouseOut={() => setIsHovered3(false)}
              />
            </div>
            <h4 className='naming'>
            The Saba-ly Therapeutic Institute </h4>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default Products;



