import React, { useEffect, useState } from "react";

const Welcome = () => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false); // New state for fade-out
  const texts = [
    "Welcome to the \n The Saba-ly Company",
    "Empowering Dreams,\n  Building Successful Futures"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true); // Start fade-out
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFadeOut(false); // Reset fade-out after changing text
      }, 1000); // Duration of fade-out
    }, 4000); // Time for each text to display (3000ms for text + 1000ms for fade-out)

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  return (
    <div className="blur-container">
      <div className="background"></div>
      <div className={`company-text ${fadeOut ? 'fade-out' : ''}`}>
        {texts[currentTextIndex].split('\n').map((line, index) => (
          <div key={index}>{line}</div> // Render each line in a separate div
        ))}
      </div>
    </div>
  );
};

export default Welcome;



