// import React from 'react';
// import img from "../Logo/img.png";

// const ServiceSection = () => {
//   return (
//     <div className="service-container">
//       <div className="service-row">
//         <div className="service-col text">
//           <h1>At The Saba-ly, we offer affordable, innovative solutions to drive client success.</h1>
//         </div>
//         <div className="service-col image">
//           <img src={img} alt="Service" />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ServiceSection;
import React, { useEffect, useRef, useState } from 'react';
import img from "../Logo/img.png";
import { Container, Row,Col } from 'react-bootstrap';

const ServiceSection = () => {
  const serviceRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setInView(true);
          } else {
            setInView(false);
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the component is visible
      }
    );

    if (serviceRef.current) {
      observer.observe(serviceRef.current);
    }

    return () => {
      if (serviceRef.current) {
        observer.unobserve(serviceRef.current);
      }
    };
  }, []);

  return (
    <Container ref={serviceRef} className={`service-container ${inView ? "animate" : ""}`}>
      <Row className="service-row">
        <Col className="service-col text" md={6} xs={12}>
        <h2>Our Vision</h2>
          <h1>At the Saba-ly,We offer affordable, innovative solutions to drive client success.</h1>
        </Col>
        <Col className="service-col image" md={6} xs={12}>
          <img src={img} alt="Service" />
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceSection;