import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import logo from "../Logo/sabamin.png";

function Header() {
  return (
    <Navbar expand="lg" className="nav" sticky="top">
      <Container fluid>
        <Navbar.Brand className="logo">
          <img src={logo} height={60} width={60} alt="here" style={{ marginLeft: "40%",borderRadius:"30px" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="it">
            <Nav.Link>
              <NavLink to="/">Home <i className="fa-solid fa-house"></i></NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink to="/Aboutus">About Us <i className="fas fa-user"></i></NavLink>
            </Nav.Link>
            <NavDropdown title="Our Projects" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <NavLink to="/Project/book">Alia’s E-Books Institute</NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink to="/Project/jerri">Jerri’s Couture & Scents</NavLink>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <NavLink to="/Project/mind">The Saba-ly Therapeutic Institute</NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link>
              <NavLink to="/contactus">Contact Us <i className="fa-solid fa-phone"></i></NavLink>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

