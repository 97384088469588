import React from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import ndure from "../Logo/sir5.png";
import ndure1 from "../Logo/sir7.png";
import ndure2 from "../Logo/sir4.png";
import aboutus from "../Logo/img4.png";
import AnimatedBlocks from "./AnimatedBlock";
import Abt from "./Abt";
const AboutUs = () => {
  return (
    <>
    <div>
    <Container
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop:"5%",
    paddingBottom:"5%"
  }}
>
  <img 
    src={aboutus} 
    alt="" 
    className="img-fluid" 
  />
</Container>

      <AnimatedBlocks />
      <Container>
        <Row style={{ paddingTop: "3%" }}>
          <Col md={6}>
            <div className="aboutus">
              <h4 style={{ color: "#ff8000" }}> Our history</h4>
              <h5 style={{ textAlign: "center", fontSize: "45px" }}>
                <strong>How we reached to this level</strong>
              </h5>
              <strong>Company:</strong> The Saba-ly Company Founded by Mr Baqir
              Ahmad Rabbani at Lahore in 2022, began with multiple educational
              school system & casual shoes.After trademarking in 2023, it's
              launched Jerri's Couture,offering diverse products. The Saba-ly
              Company support to youth through digital education system & speech
              therapy across 16 major cities in Punjab Pakistan and strengthen
              300 employees as well. <br />
              <strong>Baqir Ahmad Rabbani(CEO):</strong> A young professional
              having master's in Finance & Administration also done a
              professional degree in next generation accounting from Global
              Education system UK. Gained valuable experiences by working in
              Development Sector with UNESCO program for the Girls right to
              education & in the Corporate Sector at Lahore based Shoe industry.
            </div>
          </Col>
          <Col md={6}>
            <Carousel>
              <Carousel.Item>
                <img
                  src={ndure}
                  alt="this is img"
                  className="img-fluid"
                  style={{ height: "420px", width: "600px" }}
                />
              </Carousel.Item>

              <Carousel.Item>
                <img
                  src={ndure1}
                  alt="this is img"
                  className="img-fluid"
                  style={{ height: "420px", width: "600px" }}
                />
              </Carousel.Item>

              <Carousel.Item>
                <img
                  src={ndure2}
                  alt="this is img"
                  className="img-fluid"
                  style={{ height: "420px", width: "600px" }}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container></div>
      <Abt/>
      <AnimatedBlocks/>
    </>
  );
};

export default AboutUs;
