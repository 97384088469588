import React from "react";
import Slider from "./Slider";
import Welcome from "./Welcome";
import Projects from "./Projects";
import AnimatedBlocks from "./AnimatedBlock";
import Intro from "./Intro";

const Homepage = () => {
  return (
    <div>
      <Welcome />
      <AnimatedBlocks />
      <Slider />
      <AnimatedBlocks />
      <Projects />
      <Intro/>
      <AnimatedBlocks/>
    </div>
  );
};

export default Homepage;
