import React from 'react'
import { Col, Container,Row } from 'react-bootstrap'
import aboutus from "../Logo/2.png";
import contactus from "../Logo/img3.png";
import AnimatedBlocks from './AnimatedBlock';
import Brand from './Brand';
const ContactUs = () => {
  return (
    <>
  <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop:"5%",
    paddingBottom:"5%"
        }}
      >
        <img src={contactus} alt="" className="img-fluid" />
      </Container>
      <AnimatedBlocks/>
    <Container style={{paddingTop:"5%",paddingBottom:"5%"}}>      
<Row>
<Col md={6} xs={12}>
    <img src={aboutus} alt="" className="img-fluid" style={{paddingTop:"10px"}} />
</Col>
 
  <Col md={6} xs={12}>
  <div  style={{display:"flex"}}> <h3 style={{color:"#ff8000"}}>CUSTOMER CARE</h3></div>
      <div  style={{display:"flex"}}><h5>via Call:</h5> <p className='Cntc'> PAK : +92300 0690559</p></div>
      <div  style={{display:"flex"}}><h5>via e-mail:</h5> <p className='Cntc'>thesabaly@outlook.com</p></div>
      <div  style={{display:"flex"}}><h5>Operational Days:</h5> <p className='Cntc'>Monday-Saturday</p></div>
      <div  style={{display:"flex"}}><h5>Operational Hours:</h5> <p className='Cntc'>8:00 AM-4:00 PM</p></div>
      <div  style={{display:"flex"}}><h5>Address:</h5> <p className='Cntc'>Punjab Housing Society Kasur Road, Lahore</p></div>
      </Col>
     </Row>
    </Container>
    <Brand/>
    <AnimatedBlocks/>
    </>
  )
}

export default ContactUs
