import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import facebook from "../Logo/fb1.png";
import instagram from "../Logo/insta2.png";
import google from "../Logo/google3.png";
import whatsapp from "../Logo/whatsapp.png";
import linkin from "../Logo/link.png";
import tiktok from "../Logo/tik.png";
import backgroundImg from "../Logo/bg.png";
const Brand = () => {
  return (
    <>
      <div
        style={{
          paddingTop: "10%",
          paddingBottom: "10%",
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "orange",
        }}
      >
        <Container>
          <h1 className="head">Connect with Us</h1>
          <br />
          <Row style={{ paddingTop: "2%" }}>
            <Col md={2} xs={4}>
              <a href="https://www.linkedin.com/in/the-saba-ly-tm-65b954331?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
                <img src={linkin} alt="" height={70} width={70} />
              </a>
            </Col>
            <Col md={2} xs={4}>
              <a href="https://www.instagram.com/thesabaly362?utm_source=qr&igsh=dnZoZXgxOWhhanBq">
                <img src={instagram} alt="" height={70} width={70} />
              </a>
            </Col>
            <Col md={2} xs={4}>
              <a href="https://www.tiktok.com/@thesabaly?_r=1&_d=eagke1515fk0ld&sec_uid=MS4wLjABAAAARabcH-1UZaXac4x5X6InB8zem-nndHi_c4zMBvHztcGYwdHyGzF7NKcTlp7WJO5g&share_author_id=7309512874718725126&sharer_language=en&source=h5_m&u_code=ebcc6gcj9m1kjj&timestamp=1728891153&user_id=7309512874718725126&sec_user_id=MS4wLjABAAAARabcH-1UZaXac4x5X6InB8zem-nndHi_c4zMBvHztcGYwdHyGzF7NKcTlp7WJO5g&utm_source=copy&utm_campaign=client_share&utm_medium=android&share_iid=7407711817442019079&share_link_id=1062a6fb-f19e-4716-ac64-e19c83ba4ed3&share_app_id=1233&ugbiz_name=ACCOUNT&ug_btm=b8727%2Cb0229&social_share_type=5&enable_checksum=1">
                <img src={tiktok} alt="" height={70} width={70} />
              </a>
            </Col>
            <Col md={2} xs={4}>
              <a href="https://www.facebook.com/share/QFG2xeqdDdqhdPfD/">
                <img src={facebook} alt="" height={70} width={70} style={{borderRadius:"35px"}} />
              </a>
            </Col>
            <Col md={2} xs={4}>
              <a href="https://sterictraders.com/">
                <img src={google} alt="" height={70} width={70} />
              </a>
            </Col>
            <Col md={2} xs={4}>
              <a href="https://wa.me/message/MHEXZAXTYMWQJ1">
                <img src={whatsapp} alt="" height={70} width={70} />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Brand;
