import React from "react";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { NavLink } from "react-router-dom";
export default function App() {
  return (
    <div>
      <MDBFooter bgColor="black" className="text-white text-center text-lg-left">
        <MDBContainer className="p-4">
          <MDBRow>
            <MDBCol lg="6" md="12" className="mb-4 mb-md-0">
              <h4 className="text-uppercase">About</h4>

              <p className="para">
                Empowering Youth & To become the region’s leading supply chain
                company by achieving consistent and sustained growth and
                delivering value to its business partners and its people..
              </p>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase">Links</h5>
              <div className="footer">
                <ul className="list-unstyled mb-0">
                  <li>
                    <NavLink to="/Project/book">
                      Alia’s E-Books Institute
                    </NavLink>
                  </li>
                  <br />
                  <li>
                    <NavLink to="/Project/jerri">
                      {" "}
                      Jerri’s Couture & Scents{" "}
                    </NavLink>
                  </li>
                  <br />
                  <li>
                    <NavLink to="/Project/mind">
                      {" "}
                      The saba-ly Therapeutic Institute 
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/Product/women"> Women</NavLink>
                  </li>

                  <li>
                    <NavLink to="/Aboutus"> AboutUs</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contactus"> ContactUs</NavLink>
                  </li> */}
                </ul>
              </div>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase mb-0">Customer Care</h5>
              <div style={{ paddingTop: "5px" }}>
                <ul className="list-unstyled">
                  <li>
                    <p> PAK : +92300 0690559</p>
                  </li>
                  <li>
                    <p>Send Email: thesabaly@outlook.com</p>
                  </li>
                  <li>
                    <p>Location: Punjab Housing Society Kasur Road Lahore</p>
                  </li>
                </ul>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>

        <div
          className="text-center p-3"
          style={{ backgroundColor: "#ff8000" }}
        >
          &copy; {new Date().getFullYear()} Copyright:{" "}
          <a className="text-white" href="https://Saba-lly.com/">
            The Saba-ly.com
          </a>
        </div>
      </MDBFooter>
    </div>
  );
}
