import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Ebooks from "./Components/Ebooks";
import Jerri from "./Components/Jerri";
import AboutUs from "./Components/AboutUs";
import ContactUs from "./Components/ContactUs";
import Therapy from "./Components/Therapy";
import Homepage from "./Components/Homepage";
import AnimatedBlock from "./Components/AnimatedBlock";
import ScrollToTop from "./Components/ScrollToTop";

function App() {
  return (
    <div>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/Project/book" element={<Ebooks />} />
        <Route path="/Project/jerri" element={<Jerri />} />
        <Route path="/Project/mind" element={<Therapy />} />
      </Routes>
      <Footer />
      <AnimatedBlock />
    </div>
  );
}

export default App;

