import React, { useEffect, useState } from 'react';
import design from "../Logo/design4.png";
import backgroundImg from "../Logo/bg.png";
import { Container, Row, Col } from 'react-bootstrap';

const Intro = () => {
  const fullText = "Empowering youth, delivering value, and excelling in supply chain solutions.";
  const [displayedText, setDisplayedText] = useState('');
  const [isComplete, setIsComplete] = useState(false); // State to track completion
  
  useEffect(() => {
    let typingInterval;
    let resetTimeout;

    const startTyping = () => {
      typingInterval = setInterval(() => {
        setDisplayedText((prev) => {
          if (prev.length < fullText.length) {
            return fullText.slice(0, prev.length + 1);
          } else {
            clearInterval(typingInterval);
            setIsComplete(true); // Mark text as complete
            return prev; // Return full text
          }
        });
      }, 150);
    };

    if (isComplete) {
      resetTimeout = setTimeout(() => {
        setDisplayedText(''); // Reset text
        setIsComplete(false); // Reset completion state
        startTyping(); // Start typing again
      }, 5000); // Wait 5 seconds after full text display
    } else {
      startTyping(); // Start typing the text
    }

    return () => {
      clearInterval(typingInterval);
      clearTimeout(resetTimeout);
    };
  }, [isComplete]); // Run effect when isComplete changes

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "black",
      }}
    >
      <Container>
        <Row>
          <Col md={6} xs={12}>
            <img src={design} alt="design" className='img-fluid' />
          </Col>
          <Col md={6} xs={12}>
            <h1 className='osm'>{displayedText}</h1>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Intro;



